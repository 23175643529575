<script setup lang="ts">
import type { NuxtError } from '#app';

const { error } = defineProps<{
  error: NuxtError;
}>();

useHead({
  title: 'Error',
});

const passThrough = computed(() =>
  'toJSON' in error ? error.toJSON() : error,
);
</script>

<template>
  <div>
    <UMain>
      <UContainer>
        <UPage>
          <UPageError :error="passThrough" />
        </UPage>
      </UContainer>
    </UMain>

    <UNotifications />
  </div>
</template>

<style lang="scss">
@import '@fontsource/noto-sans/400.css';
@import '@fontsource/noto-sans/500.css';
@import '@fontsource/noto-sans/600.css';
@import '@fontsource/noto-sans/700.css';
</style>
