<script setup lang="ts">
import defaultColors from 'tailwindcss/colors';
import type { DefaultColors } from 'tailwindcss/types/generated/colors';

const appConfig = useAppConfig();

const color = computed(
  () => defaultColors[appConfig.ui.primary as keyof DefaultColors][500],
);

useHead({
  title: 'Saif Mahmud',
  meta: [
    { charset: 'utf-8' },
    { name: 'viewport', content: 'width=device-width, initial-scale=1' },
    { key: 'theme-color', name: 'theme-color', content: color },
  ],
  link: [
    { rel: 'shortcut icon', href: '/favicon.png' },
    { rel: 'me', href: 'https://mastodon.social/@sikhlana' },
  ],
  htmlAttrs: {
    lang: 'en',
  },
});
</script>

<template>
  <div>
    <NuxtLoadingIndicator />

    <UMain>
      <NuxtPage />
    </UMain>
  </div>
</template>

<style lang="scss">
@import '@fontsource/noto-sans/400.css';
@import '@fontsource/noto-sans/400-italic.css';
@import '@fontsource/noto-sans/700.css';
</style>
